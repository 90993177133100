import * as React from "react"
import appInsights from '../../../../AppInsights'; 
import Infrastructure from "../../../route/infrastructure"





// markup
const IndexPage = (props) => {
  return (
    <React.Fragment>
      <Infrastructure props={props} />
      </React.Fragment>
  )
}

export default IndexPage
